import React from 'react';
import { Link } from 'gatsby';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import BendableLogo from '../../svg/Bendable_Logo.svg';

const PrimaryAppBar = (props) => {
  const { instanceName, backPath } = props;

  return (
    <Box 
      component="header" 
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'fixed', 
        top: 0,
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 10px 25px rgb(0 0 0 / 25%)',
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
        paddingLeft: { xs: '1rem' , lg: '6.25rem' },
        paddingRight: { xs: '1rem' , lg: '6.25rem' },
        zIndex: 10,
        alignItems: 'center',
        boxSizing: 'border-box'      
      }}
    >

      <Paper
        elevation={0}
        sx={{ 
          width: { xs: '37.5px', lg: '74px' },
          height: { xs: '37.5px', lg: '74px' },
        }}
      >
        <BendableLogo style={{ height: '100%' }} />     
      </Paper>

      <Box 
        sx={{
          fontSize: { xs: '1.1rem', lg: '1.875rem' },
          lineHeight: { xs: '1.1rem', lg: '1.75rem' },
          letterSpacing: { xs: '-0.3px', lg: '-0.5px' },
          marginBottom: { xs: '-6px', lg: '-9px' },
          marginLeft: '0.4375rem',
          fontFamily: "Cooper Std, sans-serif",
          color: '#000000'
        }}
      >
        Bendable
      </Box>

      <Box 
        sx={{
          fontSize: { xs: '1.1rem', lg: '1.875rem' },
          lineHeight: { xs: '1.1rem', lg: '1.75rem' },
          letterSpacing: { xs: '-0.3px', lg: '-0.5px' },
          marginBottom: { xs: '-6px', lg: '-9px' },
          marginLeft: '0.4375rem',
          fontFamily: "Cooper Std, sans-serif",
          color: '#E9AD21',
          flexGrow: 1
        }}
      >
        {instanceName}
      </Box>


      <div>
        <Link to={backPath}>
          <Stack direction="row">
            <div style={{ paddingTop: '2px', paddingRight: '5px' }}>
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15 7.3132C15 7.56181 14.9012 7.80024 14.7254 7.97603C14.5496 8.15183 14.3112 8.25059 14.0626 8.25059H3.20194L7.22712 12.2739C7.31427 12.361 7.38341 12.4645 7.43058 12.5784C7.47775 12.6923 7.50202 12.8143 7.50202 12.9376C7.50202 13.0608 7.47775 13.1829 7.43058 13.2967C7.38341 13.4106 7.31427 13.5141 7.22712 13.6012C7.13996 13.6884 7.0365 13.7575 6.92262 13.8047C6.80875 13.8519 6.6867 13.8761 6.56344 13.8761C6.44019 13.8761 6.31814 13.8519 6.20426 13.8047C6.09039 13.7575 5.98692 13.6884 5.89977 13.6012L0.275395 7.97687C0.188099 7.8898 0.118838 7.78635 0.0715815 7.67247C0.0243247 7.55858 0 7.4365 0 7.3132C0 7.1899 0.0243247 7.06781 0.0715815 6.95392C0.118838 6.84004 0.188099 6.7366 0.275395 6.64952L5.89977 1.02515C6.07578 0.84913 6.31452 0.750244 6.56344 0.750244C6.81237 0.750244 7.0511 0.84913 7.22712 1.02515C7.40314 1.20117 7.50202 1.4399 7.50202 1.68882C7.50202 1.93775 7.40314 2.17648 7.22712 2.3525L3.20194 6.3758H14.0626C14.3112 6.3758 14.5496 6.47456 14.7254 6.65036C14.9012 6.82615 15 7.06458 15 7.3132Z" fill="#A8A8A8"/>
              </svg>
            </div>
            <Box
              component="div"
              sx={{
                fontSize: { xs: '1rem', lg: '1rem' },
              }}
            >
              Back
            </Box>
          </Stack>
        </Link>
      </div>

    </Box>
  );
};

export default PrimaryAppBar;