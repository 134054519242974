import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BendableLabsLogo from './BendableLabsLogo';

const Footer = (props) => {

  return (
    <Grid
      id="footer-grid-container"
      container
      direction={{ xs: 'column', md: 'row'}}
      // alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        background: '#ffffff',
        // alignItems: 'center',
        marginTop: { xs: '36px' , sm: '0px' }, 
        marginBottom: { xs: '41px' , sm: '30px' }, 
      }}
    >

        <Grid 
          item 
          xs={12} 
          md={3}
          lg={3}
          sx={{
            height: 0,
            paddingLeft: { xs: '0px', sm: '0px' },
            paddingBottom: { xs: '0px', sm: '0px' },
          }}
        >
          <Box sx={{
            width: '20px',
            height: '20px',
            // backgroundColor: { xs: 'red', sm: 'orange', md: 'yellow', lg: 'green', xl: 'blue' }
          }}
          />
        </Grid>

        <Grid 
          item 
          xs={12} 
          md={3}
          lg={3}
          sx={{
            paddingTop: { xs: '0px', md: '24px' },
            paddingBottom: { xs: '28px', sm: '36px' },
            //backgroundColor: 'red',
          }}
        >
          <Paper
              elevation={0}
              sx={{ 
                width: { xs: '60%', sm: '50%', md: '100%' },
                margin: { xs: '0 auto', md: '0px' },
              }}
            >
              <StaticImage 
                src="../../images/B&T-logo_tall canvas.jpg" 
                alt="Baker & Taylor Logo"
              />   
            </Paper>
        </Grid>


        <Grid 
          item 
          xs={12} 
          md={3}
          lg={3}
          sx={{
            paddingTop: { xs: '0px', md: '26px', lg: '30px', xl: '38px' },
            paddingLeft: { xs: '0px', sm: '60px', xl: '80px' },
            paddingRight: { xs: '0px', sm: '60px', xl: '100px' },
            // backgroundColor: 'blue',
          }}
        >
          <BendableLabsLogo width={240} />
        </Grid>

        <Grid 
          item 
          xs={12} 
          md={3}
          lg={3}
          sx={{
            height: 0,
            paddingLeft: { xs: '0px', md: '0px' },
            paddingBottom: { xs: '28px', sm: '36px' },
          }}
        >
          <Box sx={{
            width: '20px',
            height: '20px',
            // backgroundColor: { xs: 'red', sm: 'orange', md: 'yellow', lg: 'green', xl: 'blue' }
            }}
          ></Box>
        </Grid>



      </Grid>
  );
};

export default Footer;