import React from 'react';

import Box from '@mui/material/Box';

// import backgroundImage from '../../images/Bendable_poster_in_library.jpg';

const BendablePosterBG = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '338px', lg: '100%' },
        background: `url(https://bendable.s3.us-west-1.amazonaws.com/mg_shared_hero1.jpg) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      &nbsp;
    </Box>
  );
};

export default BendablePosterBG;