import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const ComingSoonTo = (props) => {
  const { libraryName } = props;

  return (
    <Box
      sx={{
        opacity: 99.9,
        // display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        height: { xs:'300px', lg: '732px' },
        // flexGrow: 1,
        background: '#E9AD21',
        paddingTop: { xs:'31px', lg: '129px' },
        paddingBottom: { xs:'53px', lg: '156px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '99px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'35px', lg: '54px' },
          lineHeight: { xs:'40px', lg: '60px' },
          letterSpacing: '-1.125px',
          color: '#FFFFFF',
          maxWidth: { xs:'220px', sm: '500px', lg: '500px', xl: '750px' },
          paddingBottom: '21px',
        }}
      >
        Bendable is coming to {libraryName} soon!
      </Typography>

    </Box>
  );
};

export default ComingSoonTo;